<template>
  <v-col cols="12" v-if="bannerText">
    <v-row justify="center">
      <v-col
        cols="11"
        :class="$vuetify.breakpoint.lgAndUp ? 'py-10' : ''"
        class="text-center"
      >
        <span
          class="text-body-1 text-lg-h4 font-weight-bold"
          style="white-space: break-spaces"
        >
          {{ bannerText }}
        </span>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { axiosInstance } from "@/plugins/axios";

export default {
  name: "BannerText",
  data() {
    return {
      bannerText: "",
    };
  },
  mounted() {
    this.getBanner();
  },

  methods: {
    async getBanner() {
      const headers = {
        headers: {
          Authorization: `Token ${process.env.VUE_APP_TOKEN}`,
          "Content-Type": "application/json",
          Site: process.env.VUE_APP_SITE,
          Client: "App",
          Accept: "*/*",
        },
      };

      axiosInstance
        .get(`/api/v1/settings/app/`, headers)
        .then((response) => {
          const { is_active, text_local } = response.data.data;
          this.bannerText = is_active ? text_local : null;
        })
        .catch((e) => {
          const payload = {
            msg: e.response.data.detail
              ? e.response.data.detail
              : i18n.t("problem"),
            success: false,
          };
          this.$store.commit("user/OPEN_SNACKBAR", payload);
        });
    },
  },
};
</script>
